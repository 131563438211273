<template>
	<div>
		<v-col cols="10" sm="8" class="pa-0 mt-10 py-10 mt-10 mx-auto">
			<v-skeleton-loader
				v-if="isLoad"
				type="paragraph"
				class="mt-3"
			></v-skeleton-loader>
			<div class="font-bold text-2xl sm:text-4xl mt-10 pt-10">{{dataItem.name || '-'}}</div>
			<div class="text-sm py-4">{{dataItem.lastUpdate || '-'}}</div>
			<img :src="baseUrlImage + dataItem.image.file_path" width="100%" class="rounded-md mt-8 img-detail" />
			<div class="white grid justify-items-center px-0 pt-5">
				<v-col cols="12" class="pa-0">
					<div class="font-bold text-base sm:text-xl my-5 text-left" v-if="bahasa">Deskripsi</div>
					<div class="font-bold text-base sm:text-xl my-5 text-left" v-else>Description</div>
					<div class="text-left mb-10" v-html="dataItem.description"></div>
				</v-col>
			</div>
		</v-col>
	</div>
</template>
<script>
import axios from 'axios';
import Swal from 'sweetalert2';
export default {
	props: {
		bahasa: {
			type: Boolean,
			default: true
		},
		id: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			isLoad: false,
			dataItem: {},
			baseUrl: process.env.VUE_APP_BASE_URL,
			baseUrlImage: process.env.VUE_APP_BASE_ASSET,
		}
	},
	watch: {
		language() {
			this.getData();
		}
	},
	computed: {
		pageName() {
      let path = this.$route.name;
      let page = path.split('/').join('');
			return page.includes('-') ? page.split('-').join(' ') : page;
    },
		language() {
      return this.bahasa ? 'ID' : 'ENG';
    },
	},
	mounted() {
		this.getData();
	},
	methods: {
		goToProgram(link, param) {
			this.$router.push({
        name: link,
        params: {
          id: param
        }
      });
		},
		async getData() {
			this.isLoad = true;
			let param = {
				lang: this.language,
			}
      await axios.get(this.baseUrl + '/performance/' + this.id, {params: param}).then(response => {
        this.dataItem = response.data.data;
      })
      .catch(error => {
				if (error.response.data.code === 404) {
					this.$router.push({name: 'NotFound'})
				} else {
					this.isLoad = false;
					Swal.fire('Failed!', error.response.data.message, 'error');
				}
      })
      .finally(() => this.isLoad = false);
		}
	}
}
</script>